import { withCmsBlockProvider } from 'Component/CmsBlockProvider';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { CmsBlockContainer as SourceCmsBlockContainer } from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace Pwa/Component/CmsBlock/Container/CmsBlockContainer */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    componentDidUpdate(prevProps) {
        const { blocks, identifier } = this.props;
        const { blocks: prevBlocks, identifier: prevIdentifier } = prevProps;

        if (prevBlocks.length !== blocks.length) {
            this._getCmsBlock();
        }

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock() {
        const { identifier, shared, hasBlock, getBlock } = this.props;

        if (shared) {
            if (!hasBlock(identifier)) {
                return;
            }

            this.setState({ cmsBlock: getBlock(identifier) });
        } else {
            this.fetchData([CmsBlockQuery.getQuery({ identifiers: [identifier] })], ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
            });
        }
    }
}

export default withCmsBlockProvider(CmsBlockContainer);
