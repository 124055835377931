import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Pwa/Component/NewsletterSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = () => [
    {
        type: FIELD_TYPE.email,
        label: __('Enter your email address'),
        attr: {
            name: 'newsletterEmail',
            placeholder: __('Enter your email address'),
            'aria-label': __('Email address'),
        },
        validateOn: ['onSubmit'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.email,
            isRequired: true,
        },
    },
];

export default newsletterSubscriptionForm;
