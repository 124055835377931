import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ConsentsContainer } from 'Component/Consents/Consents.container';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';

/** @namespace Pwa/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
});

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NewsletterSubscription/NewsletterSubscription.dispatcher'
);

/** @namespace Pwa/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    subscribeToNewsletter: (email, consentIds) =>
        NewsletterSubscriptionDispatcher.then(({ default: dispatcher }) =>
            dispatcher.subscribeToNewsletter(dispatch, email, consentIds)
        ),
});

/** @namespace Pwa/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    onFormSubmit(form, fields) {
        const { subscribeToNewsletter, allowGuestSubscribe, isSignedIn, showErrorNotification } = this.props;

        const acceptedConsents = ConsentsContainer.getAcceptedConsents(fields);
        const { value: email } = fields.find(({ name }) => name === 'newsletterEmail') || {};

        if (!allowGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.')
            );

            return;
        }

        this.setState({ isLoading: true });

        subscribeToNewsletter(email, acceptedConsents).then(this.onFormSubmitDone).catch(this.onFormSubmitDone);
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscriptionContainer));
