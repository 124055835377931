import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { THANK_YOU_PAGE } from 'Route/ThankYouPage/ThankYouPage.config';
import { mapDispatchToProps, mapStateToProps as sourceMapStateToProps } from 'SourceComponent/Footer/Footer.container';
import history from 'Util/History';

import Footer from './Footer.component';

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    absoluteFooter: state.ConfigReducer.absolute_footer,
    newsletterEnabled: state.ConfigReducer.newsletter_general_active,
});

export { mapDispatchToProps };

/** @namespace Pwa/Component/Footer/Container/FooterContainer */
export class FooterContainer extends PureComponent {
    componentDidMount() {
        this.headerClickListener();
    }

    componentWillUnmount() {
        this.headerClickListener('remove');
    }

    headerClickListener(type = 'add') {
        const {
            device: { isMobile },
        } = this.props;

        if (!isMobile) return;

        if (type === 'remove') {
            document.removeEventListener('click', this.handleHeaderClick.bind(this));
            return;
        }

        document.addEventListener('click', this.handleHeaderClick.bind(this));
    }

    handleHeaderClick(e) {
        const { target } = e;
        const FooterLinksGroups = document.querySelectorAll('.Footer-LinksGroup');

        if (target?.tagName === 'H3') {
            target.classList.toggle('isActive');
            FooterLinksGroups.forEach((group) => {
                const header = group.querySelector('h3');
                if (target !== header) {
                    header.classList.remove('isActive');
                }
            });
        }
    }

    containerProps() {
        const { absoluteFooter, newsletterEnabled } = this.props;
        const {
            location: { pathname },
        } = history;
        const isCheckout = pathname.includes(CHECKOUT_URL) || pathname.includes(THANK_YOU_PAGE);

        return {
            absoluteFooter,
            isCheckout,
            newsletterEnabled,
        };
    }

    render() {
        return <Footer {...this.props} {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterContainer));
