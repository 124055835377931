import Consents from 'Component/Consents';
import { CONSENTS_NEWSLETTER } from 'Component/Consents/Consents.config';
import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Icons from 'Component/Icons';
import newsletterSubscriptionForm from 'Component/NewsletterSubscription/NewsletterForm.form';
import { NewsletterSubscription as SourceNewsletterSubscription } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.style';

/** @namespace Pwa/Component/NewsletterSubscription/Component/NewsletterSubscription */
export class NewsletterSubscription extends SourceNewsletterSubscription {
    className = 'NewsletterSubscription';

    get fieldMap() {
        return newsletterSubscriptionForm();
    }

    renderAgreements() {
        return <Consents type={CONSENTS_NEWSLETTER} />;
    }

    renderFields() {
        return (
            <div block="FieldForm" elem="Fields">
                {Object.entries(this.fieldMap).map(this.renderField)}
                {this.renderAgreements()}
            </div>
        );
    }

    renderFormBody() {
        const { isLoading } = this.props;

        return (
            <div block="FieldForm" elem="Body" mods={{ isLoading }}>
                <div block="FieldForm" elem="Fields">
                    {this.fieldMap.map(this.renderSection.bind(this))}
                    {this.renderAgreements()}
                </div>
            </div>
        );
    }

    renderTitle() {
        return (
            <div block={this.className} elem="Title">
                {__('Subscribe to the newsletter')}
            </div>
        );
    }

    renderActions() {
        return (
            <button type={FIELD_TYPE.submit} aria-label={__('Submit')}>
                <Icons name="arrow_right" fill="#10069F" />
            </button>
        );
    }

    renderSection(section) {
        const { fields, attr: { name = '' } = {}, name: sectionName } = section;

        if (name === 'newsletterEmail') {
            return (
                <div block={this.className} elem="Input">
                    {this.renderActions()}
                    <Field {...section} key={name} />
                </div>
            );
        }

        // If contains attr fields then outputs data as fields
        if (Array.isArray(fields)) {
            return (
                <FieldGroup {...section} key={name || sectionName}>
                    {this.renderActions()}

                    {fields.map(this.renderSection.bind(this))}
                </FieldGroup>
            );
        }

        return <Field {...section} key={name} />;
    }

    render() {
        return (
            <div block={this.className} elem="Wrapper">
                {this.renderTitle()}
                {super.render()}
            </div>
        );
    }
}

export default NewsletterSubscription;
