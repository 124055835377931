import Html from 'Component/Html';
import { CmsBlock as SourceCmsBlock } from 'SourceComponent/CmsBlock/CmsBlock.component';

import { EMPTY_BLOCK_ID } from './CmsBlock.config';
/** @namespace Pwa/Component/CmsBlock/Component/CmsBlock */
export class CmsBlock extends SourceCmsBlock {
    render() {
        const {
            cmsBlock: { identifier, content, disabled },
            blockType,
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        if (content?.includes(EMPTY_BLOCK_ID)) {
            return <div />;
        }

        return (
            <div block="CmsBlock" elem="Wrapper" mods={{ type: blockType }}>
                <Html content={content} />
            </div>
        );
    }
}

export default CmsBlock;
